<template>
  <b-modal
    id="modal-retur"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal"
  >
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col
          cols="3"
          class="d-flex align-items-center"
        >
          <div
            style="cursor: pointer;"
            @click="$bvModal.hide('modal-retur')"
          >
            <feather-icon
              size="24"
              class="text-dark font-weight-bolder"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col
          cols="6"
          class="d-flex justify-content-center align-items-center"
        >
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            <span class="text-dark h6 font-weight-bolder mb-0 text-center" v-if="returs && returs.invoice_number">No Invoice:</span>
            {{ is_edit == false ? returs && returs.invoice_number || 'Tambah Retur Pembelian' : returs.transaction && returs.transaction.invoice_number || returs.transaction && returs.transaction.po_so_id }} {{ is_edit === true ? `&bull; No Retur: ${returs.retur_number}` : '' }}
          </h1>
        </b-col>
        <b-col
          cols="3"
          class="text-right"
        >
          <b-button
            v-if="isApprove == false"
            class="px-3"
            :disabled="disabledApprove || isLoading"
            @click="submit(null)"
          >
            {{ is_edit == true ? 'Update Retur' : 'Tambah Retur' }}
          </b-button>
        </b-col>
      </b-row>
    </header>
    <b-container class="mt-5 pt-5">
      <b-row
        class="mx-0 mt-5 px-5 justify-content-center"
      >
        <b-col
          class=""
          cols="12"
        >
          <b-table
            class="mb-0"
            responsive="sm"
            :items="items"
            :fields="fields"
          >
            <!-- A custom formatted header cell for field 'name' -->
            <template #head(name)="">
              <span>Nama Barang</span>
            </template>
            <template #head(qty)="">
              <span>Jumlah Barang</span>
            </template>
            <template #head(count)="" style="width: 9rem;">
              <span>Jumlah Retur</span>
            </template>
            <template #head(value)="">
              <span>Nilai Retur</span>
            </template>
            <template #head(condition)="">
              <span>Kondisi Barang</span>
            </template>

            <!-- A custom formatted column -->
            <template
              #cell(count)="data"
            >
              <b-form-group
                label=""
                label-for="v-jumlah-retur"
                class="pr-1"
              >
                <!-- <money
                  id="v-jumlah-retur"
                  v-model="qty[data.index].qty"
                  v-bind="money"
                  placeholder="Jumlah Retur"
                  class="custom__input pl-1"
                  @input="returCount(data.item.qty, data.index)"
                /> -->
                <input
                  :id="`v-jumlah-retur-${data.index}`"
                  type="text"
                  class="custom__input pl-1"
                  style="width: 9rem;"
                  @input="getRupiah(data.index)"
                  @change="getRupiah(data.index)"
                >
              </b-form-group>
            </template>

            <template #cell(value)="data">
              <span>{{ data.item.price * formPayload.items[data.index].qty || 0 | formatAmount }}</span>
            </template>

            <!-- A custom formatted column -->
            <template #cell(condition)="data">
              <b-form-group
                label=""
                class="custom__form--input"
              >
                <b-form-select
                  v-model="formPayload.items[data.index].condition"
                  label="title"
                  :options="options"
                  :disabled="qty[data.index].qty == 0 ? true : false"
                />
                <!-- class="error-validation" -->
              </b-form-group>
              <small
                v-if="messages[`items.${data.index}.condition`] && Array.isArray(messages[`items.${data.index}.condition`]) && messages[`items.${data.index}.condition`].length > 0"
                class="text-error fw-bold-500 size12"
              >{{ messages[`items.${data.index}.condition`].join(', ') }}</small>
              <small
                v-else-if="messages && Array.isArray(messages) && messages.length > 0"
                class="text-error fw-bold-500 size12"
              >{{ messages.join(', ') }}</small>
            </template>
          </b-table>

        </b-col>
        <b-col md="6" />
        <b-col
          md="6"
          class="border-left border-bottom py-5 mb-5 pl-3"
        >
          <b-row>
            <b-col cols="4">
              <h6 class="text-dark fw-bold-600 mb-3 text-center">
                Total Nilai Retur
              </h6>
            </b-col>
            <b-col cols="8">
              <span class="text-dark fw-bold-600 mb-3">{{ totalRetur || 0 | formatAmount }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
              class="mb-3 align-self-center"
            >
              <h6 class="text-dark fw-bold-600 text-center">
                Input Nilai Diskon
              </h6>
            </b-col>
            <b-col
              cols="8"
              class="mb-3"
            >
              <span
                v-if="is_edit == true"
                class="text-dark fw-bold-600"
              >{{ formPayload.discount | formatAmount }}</span>
              <b-form-group
                v-else
                label=""
                label-for="v-discount"
                class="mr-5"
              >
                <money
                  id="v-discount"
                  v-model="discountValue"
                  v-bind="money"
                  placeholder="Jumlah Retur"
                  class="custom__input px-1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <h6 class="text-dark fw-bold-600 mb-3 text-center">
                Total Pengembalian
              </h6>
            </b-col>
            <b-col cols="8">
              <span class="text-dark fw-bold-800 mb-3">{{ totalRetur - formPayload.discount || 0 | formatAmount }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div
        v-if="isApprove"
        class="footer__purchase p-2"
      >
        <b-row class="mx-0 align-items-center">
          <b-col lg="6">
            <h5 class="mb-0">
              Pilih persetujuan retur
            </h5>
          </b-col>
          <b-col lg="6">
            <div class="d-flex">
              <b-button class="btn-reject btn-block mr-1 py-2 size14" @click="submit('reject')">
                Tolak
              </b-button>
              <b-button class="btn-primary btn-block py-2 size14 mt-0" :disabled="disabledApprove" @click="submit('approve')">
                Approve
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BButton, BModal, BRow, BCol, BContainer, BTable, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification, formatRupiah, preformatFloat } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BContainer,
    BTable,
    BFormGroup,
    BFormInput,
    BFormSelect,
    vSelect,
  },
  props: {
    results: {
      type: Object,
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
    returType: {
      type: String,
    },
    getData: {
      type: Function,
    },
    isApprove: {
      type: Boolean,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      formatRupiah,
      preformatFloat,
    }
  },
  computed: {
    disabledApprove() {
      return this.formPayload.items.every(e => e.qty <= 0 || isNaN(e.qty));
    },
  },
  data() {
    return {
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      discountValue: 0,
      totalRetur: 0,
      returs: {},
      fields: ['name', 'qty', 'count', 'value', 'condition'],
      items: [],
      qty: [],
      formPayload: {
        discount: 0,
        items: [
          // {
          //   uuid: '',
          //   qty: 0,
          //   condition: '',
          // },
        ],
      },
      options: [
        { value: '', text: 'Pilih Kondisi' },
        { value: 'Baik', text: 'Baik' },
        { value: 'Rusak', text: 'Rusak' },
      ],
      messages: '',
      id: '',
      isLoading: false,
    }
  },
  watch: {
    results: {
      handler(value) {
        this.messages = ''
        this.formPayload.items = []
        this.items = []
        this.qty = []
        if (value) {
          if (this.returType === 'order') {
            this.orderValue(value)
          }
          if (this.returType === 'incoming_stock') {
            this.incomingStock(value)
          }
        }
      },
      deep: true,
    },
    qty: {
      handler(value) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < value.length; index++) {
          const element = value[index]
          // eslint-disable-next-line radix
          this.formPayload.items[index].qty = typeof element.qty === 'string' ? parseFloat(preformatFloat(element.qty)) : element.qty
        }
        this.summaryReturTotal()
      },
      deep: true,
    },
    discountValue(value) {
      const discountTotal = value.toString().replace('.', '')
      // eslint-disable-next-line radix
      this.formPayload.discount = parseInt(discountTotal)
    },
  },
  mounted() {
    if (this.results) {
      if (this.returType === 'order') {
        this.orderValue(this.results)
      }
      if (this.returType === 'incoming_stock') {
        this.incomingStock(this.results)
      }
    }
  },
  methods: {
    getRupiah(index) {
      const rupiah = document.getElementById(`v-jumlah-retur-${index}`)
      rupiah.value = formatRupiah(rupiah.value, '') || ''
      this.qty[index].qty = parseFloat(preformatFloat(rupiah.value)) || 0
      this.formPayload.items[index].qty = parseFloat(preformatFloat(rupiah.value))
    },
    returCount(qty, index) {
      for (let j = 0; j < this.qty.length; j++) {
        const element = this.qty[j]
        // eslint-disable-next-line radix
        if (j === index && parseFloat(element.qty) > qty) {
          this.qty[index].qty = qty
        }
      }
    },
    summaryReturTotal() {
      let total = 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index]
        total += element.price * this.qty[index].qty
        // console.log(element.price)
      }
      this.totalRetur = total
    },
    async submit(type) {
      const confirmationText = this.getConfirmationText(type);
      this.$swal({
        title: 'Konfirmasi',
        text: confirmationText,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.isLoading = true
          if (this.is_edit === true) {
            if (type) {
              this.formPayload.status = type
            }
            // eslint-disable-next-line no-underscore-dangle
            this.formPayload._method = 'PATCH'
          }
          this.formPayload.retur_type = this.returType

          const filteredItems = this.formPayload.items.filter(
            (item) => item.qty
          ); 
          this.formPayload.items = filteredItems;

          await this.$store.dispatch('returs/postData', {
            uuid: this.id,
            payload: this.formPayload,
          }).then(() => {
            this.isLoading = false
            this.$bvModal.hide('modal-retur')
            successNotification(this, 'Success', this.returnStatus(type))
            this.getData()
          }).catch(err => {
            this.isLoading = false
            console.log(err)
            // this.messages = err.response.data.meta.messages
            // errorNotification(this, 'Oops!', err.response.data.meta.messages)
          })
        }
      });
    },
    getConfirmationText(type) {
      if (this.is_edit === false && !type) {
        return `Apakah Anda yakin ingin membuat Retur ${this.returType === 'incoming_stock' ? 'pembelian' : 'penjualan'} baru?`;
      }
      if (this.is_edit === true && !type) {
        return `Apakah Anda yakin ingin mengubah Retur ${this.returType === 'incoming_stock' ? 'pembelian' : 'penjualan'} ini?`;
      }
      if (this.is_edit === true && type === 'approve') {
        return `Apakah Anda yakin ingin menerima Retur ${this.returType === 'incoming_stock' ? 'pembelian' : 'penjualan'} ini?`;
      }
      if (this.is_edit === true && type === 'reject') {
        return `Apakah Anda yakin ingin menolak Retur ${this.returType === 'incoming_stock' ? 'pembelian' : 'penjualan'} ini?`;
      }
      return '';
    },
    incomingStock(value) {
      this.id = this.is_edit === true ? value.uuid : ''
      this.returs = value
      this.formPayload.retur_uuid = this.is_edit === true ? value.transaction.uuid : value.uuid
      this.formPayload.discount = value.discount || 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.returs.items.length; index++) {
        const element = this.returs.items[index]
        this.qty.push({
          qty: this.is_edit === false ? '' : element.return_qty.toString().replace('.', ','),
          index,
        })
        this.formPayload.items.push(
          {
            uuid: element.uuid || element.item?.uuid,
            qty: this.is_edit === false ? '' : element.return_qty,
            condition: this.is_edit === false ? '' : element.return_condition,
          },
        )
        this.items.push({
          index,
          name: element.item?.product?.name || element.product?.name,
          qty: element.item?.qty.toString().replace('.', ',') || element.qty.toString().replace('.', ','),
          count: '',
          value: '',
          condition: this.is_edit === false ? '' : element.return_condition,
          price: element.item && element.item.price ? element.item.price : element.price,
          uuid: element.item && element.item.uuid ? element.item.uuid : element.uuid,
        })
        setTimeout(() => {
          document.getElementById(`v-jumlah-retur-${index}`).value = element.return_qty || ''
        }, 1000)
      }
    },
    orderValue(value) {
      this.id = this.is_edit === true ? value.uuid : ''
      this.returs = value
      this.formPayload.retur_uuid = this.is_edit === true ? value.transaction.uuid : value.uuid
      this.formPayload.discount = value.discount || 0
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.returs.items.length; index++) {
        const element = this.returs.items[index]
        this.qty.push({
          qty: this.is_edit === false ? '' : element.return_qty.toString().replace('.', ','),
          index,
        })
        this.formPayload.items.push(
          {
            uuid: this.is_edit === false ? element.uuid : element.item.uuid,
            qty: this.is_edit === false ? '' : element.return_qty,
            condition: this.is_edit === false ? '' : element.return_condition,
          },
        )
        this.items.push({
          index,
          name: this.is_edit === false ? element.name : element.item.name,
          qty: this.is_edit === false ? element.qty.toString().replace('.', ',') : element.item.qty.toString().replace('.', ','),
          count: '',
          value: '',
          condition: this.is_edit === false ? '' : element.return_condition,
          price: this.is_edit === false ? element.price : element.item.price,
          uuid: this.is_edit === false ? element.uuid : element.item.uuid,
        })
        setTimeout(() => {
          document.getElementById(`v-jumlah-retur-${index}`).value = element.return_qty || ''
        }, 1000)
      }
    },
    returnStatus(type) {
      if (this.is_edit === false && !type) {
        return `Berhasil menambahkan retur ${this.returType === 'incoming_stock' ? 'pembelian' : 'penjualan'}!`
      }
      if (this.is_edit === true && !type) {
        return `Berhasil mengubah retur ${this.returType === 'incoming_stock' ? 'pembelian' : 'penjualan'}!`
      }
      if (this.is_edit === true && type === 'approve') {
        return `Retur ${this.returType === 'incoming_stock' ? 'pembelian' : 'penjualan'} diterima!`
      }
      if (this.is_edit === true && type === 'reject') {
        return `Retur ${this.returType === 'incoming_stock' ? 'pembelian' : 'penjualan'} ditolak!`
      }
      return false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.bg-dark {
  background: $dark !important;
}

.btn-reject {
  background-color: #FFEEEE !important;
  border-radius: 8px;
  color: #FF2929 !important;
  border: none !important;

  &:focus {
    background-color: #FFEEEE !important;
  }
}

.text-error {
  color: #F48989;
}

#modal-retur {
  .modal-dialog {
    max-width: 100%;
    margin: 0;

    .modal-content {
      height: 100vh;

      .modal-body {
        padding: 0;
        overflow-y: scroll;

        .custom__input {
          &.error-validation {
            background: #FEEFEF;
            border: 1px solid #F48989;

            .input-group-text {
              background-color: #FEEFEF !important;
              border: 1px solid #F48989 !important;
            }
          }
        }

        label {
          font-size: 14px;
          color: $dark;
          font-weight: 600;
        }

        .vs__open-indicator {
          fill: rgba(60, 60, 60, 0.5);
        }

        .custom__input {
          height: 52px;
          background: #EFF1F5;
          border: 1px solid #E4E5EC;
          border-radius: 8px;
        }

        .vs__dropdown-toggle {
          height: 52px;
          background-color: #EFF1F5;
          border: 1px solid #E4E5EC;
          border-radius: 8px;
        }

        table {
          thead {
            tr {
              th {
                border-bottom: 1px solid #ebe9f1;
                background-color: transparent;
                border-top: none;
                padding: 1.5rem 0;
                color: #7A7F94;
                text-transform: capitalize;
                font-size: 14px;

                &:nth-child(3) {
                  width: 9rem;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                color: $dark;
                font-size: 14px;
                font-weight: 600;
                padding: 1.5rem 0;
                border-bottom: 1px solid #ebe9f1;
                vertical-align: baseline;
              }
              &:nth-child(3) {
                padding-right: 10px;
              }
            }
          }
        }

        .footer__purchase {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          background: #FFFFFF;
          box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04);
          border-radius: 16px 16px 0px 0px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="section">
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-2">
          <b-img
            class="filter-image"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
            alt="Icon-order-list-down"
            role="button"
            @click="filter.sort_type == 'asc' ? filter.sort_type = 'desc' : filter.sort_type = 'asc'"
          />
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input
              v-model="filter.search"
              placeholder="Cari Surat Jalan"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1 mt-5"
    >
      <b-spinner label="Loading..." />
    </div>
    <vue-good-table
      v-else
      max-height="80vh"
      :columns="columns"
      :rows="result"
      :fixed-header="false"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'" />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- date
        retur_number
        return_value
        status -->

        <!-- Column: Name -->
        <span v-if="props.column.field === 'transaction.date'">
          {{ props.row.transaction && props.row.transaction.date || '-' }}
        </span>

        <!-- Column: Retur Number -->
        <span v-if="props.column.field === 'retur_number'">
          <b-link @click="detailItem(props.row)">
            <span class="text-danger">
              {{ props.row.retur_number || '-' }}
            </span>
          </b-link>
        </span>

        <!-- Column: Return Number -->
        <span v-else-if="props.column.field === 'invoice_value'">
          {{ props.row.invoice_value || 0 | formatAmount }}
        </span>

        <!-- Column: Return Value -->
        <span v-else-if="props.column.field === 'return_value'">
          {{ props.row.return_value || 0 | formatAmount }}
        </span>
        
        <!-- Column: Delivery Numbers -->
        <span v-else-if="props.column.field === 'transaction.delivery_numbers'">
          <div v-if="props.row.transaction && props.row.transaction.delivery_numbers">
            <span v-if="props.row.transaction.delivery_numbers.length < 3">{{ props.row.transaction.delivery_numbers.map(e => e.delivery_number_note).slice(0, 3).join(', ') }}</span>
            <b-dropdown
              v-else
              :text="props.row.transaction.delivery_numbers.map(e => e.delivery_number_note).slice(0, 3).join(', ')"
              variant="flat-secondary"
              class="delivery-numbers px-0"
            >
              <b-row class="mx-0 pt-1">
                <b-col
                  v-for="(item, index) in props.row.transaction.delivery_numbers"
                  :key="index"
                  class=""
                >
                  <h6 class="text-dark">{{ item.delivery_number_note }}</h6>
                </b-col>
              </b-row>
            </b-dropdown>
          </div>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge
            class=""
            :variant="props.row.status == 'pending' ? 'warning' : (props.row.status == 'rejected' ? 'danger' : 'success') "
          >
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="props.row.status !== 'success' && props.row.status !== 'rejected'">
            <b-dropdown
              id="dropdown-dropleft"
              class="d-flex"
              right
            >
              <template
                #button-content
                class="btn-white text-center"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <!-- <b-dropdown-item
                v-if="checkPermission('update retur')"
                @click="editItem(props.row.uuid, 'edit')"
              >
                <span>
                  Edit Retur Pembelian
                </span>
              </b-dropdown-item> -->
              <b-dropdown-item
                v-if="checkPermission('approve retur')"
                @click="editItem(props.row.uuid, 'approve')"
              >
                <span>
                  Approve
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkPermission('reject retur')"
                @click="editItem(props.row.uuid, 'reject')"
              >
                <span>
                  Reject
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="rows && !isLoading"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalRetur
      :is_edit="isEdit"
      :retur-type="'incoming_stock'"
      :get-data="getData"
      :is-approve="isApprove"
      :results="returData"
    />
  </div>
</template>

<script>
import {
  BLink, BRow, BCol, BDropdown, BDropdownItem, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BBadge, BSpinner, BFormSelect,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import ModalRetur from '@/components/Retur/Modal/ModalRetur.vue'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BSpinner,
    ModalRetur,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BRow,
    BCol,
    BFormSelect,
    BLink,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    results: {
      type: Object,
    },
    getData: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
    typeRetur: {
      type: String,
      default: 'Retur Penjualan',
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      isApprove: false,
      filter: {
        per_page: 10,
        sort_type: 'desc',
        search: '',
        // type: '',
      },
      columns: [
        {
          label: 'Tanggal Retur',
          field: 'date',
        },
        {
          label: 'No. Surat Jalan',
          field: 'transaction.delivery_numbers',
        },
        {
          label: 'No. Retur',
          field: 'retur_number',
        },
        {
          label: 'Nama Pemasok',
          field: 'transaction.supplier_name',
        },
        {
          label: 'Nilai Pembelian (Rp)',
          field: 'invoice_value',
        },
        {
          label: 'Nilai Retur (Rp)',
          field: 'return_value',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      result: [],
      isEdit: false,
      type: 'order',
      returData: null,
    }
  },
  watch: {
    typeRetur(value) {
      if (value === 'Retur Penjualan') {
        // this.filter.type = 'order'
        this.type = 'order'
      }
      if (value === 'Retur Pembelian') {
        // this.filter.type = 'incoming_stock'
        this.type = 'incoming_stock'
      }
    },
    results: {
      handler(value) {
        this.rows = value
        this.result = value.data
      },
      deep: true,
    },
    filter: {
      handler(value) {
        this.$emit('filter', value)
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    this.rows = this.results
    this.result = this.results.data
  },
  methods: {
    editItem(id, type) {
      this.$store.dispatch('returs/getData', {
        uuid: id,
        params: '',
      }).then(result => {
        this.isEdit = true
        this.isApprove = !!(type === 'approve' || type === 'reject')
        this.returData = result.data.data
        this.$store.commit('order/setDataRetur', result.data.data)
        this.$bvModal.show('modal-retur')
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    detailItem(row) {
      const uuid = row.uuid;

      this.$router.push({
        name: 'retur.detail.index',
        params: { id: uuid },
      });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.vgt-responsive {
  height: calc(100vh - 322px) !important;
  background-color: #fff;
}
.retur-penjualan {
  position: relative;

  .transaction__filter--container {
    display: flex;
    justify-content: space-between;

    .search__export {
      width: 100%;
      .search__input {
        margin-right: 8px;
        .input-group {
          box-shadow: none !important;
          height: 48px;
          width: 100% !important;

          input {
            height: 48px;
            border: none;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            background: #F1F2F5;
          }

          .input-group-append {
            .input-group-text {
              background: #F1F2F5;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border: none;
            }
          }
        }
      }
    }
  }

  .vgt-responsive {
    height: 71vh;
    background-color: #fff;
  }

  .vgt-table.bordered {
    border-left: none;
    thead {
      th:last-child {
        text-align: center;
      }
    }
    td {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      vertical-align: middle;
      color: $dark;
      font-weight: 600;
    }
    th {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      color: #7A7F94;
      font-weight: 600;
    }

  }

  .page-item {
    .page-link {
      font-weight: 600;
      color: $secondary-2 !important;
      background-color: transparent;
    }
    &.active {
      .page-link {
        border-radius: 0;
        background-color: $light--1;
        font-weight: 600;
        color: $secondary-2 !important;
      }
    }
  }

  .page-item.prev-item ~ .page-item:nth-child(2) .page-link {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .page-item.prev-item ~ .page-item:nth-last-child(2) .page-link {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .page-item.next-item .page-link:active,
  .page-item.next-item .page-link:hover {
    background-color: transparent !important;
  }

  .pagination__container {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border: 1px solid #E4E5EC;

    .dropdown {

      button {
        font-size: 14px;
        color: $dark;
        font-weight: 600;

        &:hover {
          background-color: transparent;
        }
      }
    }

    small {
      color: $secondary-2;
      font-size: 14px;
      font-weight: 400;
    }
  }

  #dropdown-dropleft {
    .dropdown-toggle {
      border-color: transparent !important;
      background-color: transparent !important;
      color: $dark !important;

      &:hover {
        border-color: transparent !important;
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }

  .vs__open-indicator {
    fill: rgba(60, 60, 60, 0.5);
  }
}
</style>

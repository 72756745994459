<template>
  <div class="section">
    <header-page>
      <span class="text-dark fw-bold-700 size14">Retur</span>
    </header-page>
    <div class="transaction__filter--container py-2 pl-0 pr-2">
      <b-button-group>
        <b-button
          v-for="(item, index) in listFilter"
          :id="`button--filter${index}`"
          :key="`${item}-${index}`"
          class="filter--button"
          :class="{ 'active' : item == filter }"
          @click="filter = item, comp = (item == 'Retur Penjualan' ? 'TableReturPenjualan' : '' )"
        >
          {{ item }}
        </b-button>
      </b-button-group>
      <div class="d-flex search__export">
        <FilterButton @filterBase="getFilterBase" :isRetur="true" />
      </div>
    </div>

    <TableReturPenjualan
      v-if="filter == 'Retur Penjualan'"
      :results="results"
      :get-data="getData"
      :is-loading="isLoading"
      :type-retur="filter"
      @filter="getFilter"
    />
    <TableReturPembelian
      v-if="filter == 'Retur Pembelian'"
      :results="results"
      :get-data="getData"
      :is-loading="isLoading"
      :type-retur="filter"
      @filter="getFilter"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BButton, BButtonGroup, VBToggle,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import HeaderPage from '@/components/HeaderPage.vue'
import TableReturPenjualan from '@/components/Retur/TableReturPenjualan.vue'
import TableReturPembelian from '@/components/Retur/TableReturPembelian.vue'
// import ModalRetur from '@/components/Retur/Modal/ModalRetur.vue'
import FilterButton from '@/components/BaseFilter.vue'
// import Invoice from '@/components/InvoiceComponent.vue'

export default {
  title() {
    return 'Retur'
  },
  components: {
    BButton,
    HeaderPage,
    BButtonGroup,
    TableReturPenjualan,
    // ModalRetur,
    FilterButton,
    // Invoice,
    TableReturPembelian,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      listFilter: ['Retur Penjualan', 'Retur Pembelian'],
      filter: 'Retur Penjualan',
      comp: 'TableReturPenjualan',
      isEdit: false,
      filters: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
        // type: 'order',
      },
      results: {},
      filterBase: {},
      isLoading: false,
    }
  },
  created() {
    this.getData()
  },
  watch: {
    filter(value) {
      // this.isLoading = true
      // this.filterBase = {}
      if (value === 'Retur Penjualan' || value === 'Retur Pembelian') {
        this.filters.search = '';
      }
      this.getData()
    },
    filters: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
    filterBase: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  methods: {
    getFilterBase(value) {
      this.filterBase = value
    },
    getFilter(value) {
      this.filters = value
    },
    async getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filters
      queryParams.status = this.filterBase.status
      queryParams.filter_date_type = this.filterBase.filter_date_type
      queryParams.filter_date_value = this.filterBase.filter_date_value
      queryParams.page = page
      queryParams.type = this.filter === 'Retur Penjualan' ? 'order' : 'incoming_stock'
      await this.$store.dispatch('returs/getData', {
        uuid: '',
        params: queryParams,
      }).then(result => {
        this.isEdit = true
        this.results = result.data.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getIsEdit(val) {
      this.isEdit = val
      this.$bvModal.show('modal-retur')
    },
    postIsEdit() {
      this.isEdit = false
      this.$bvModal.show('modal-retur')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
